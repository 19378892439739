import React, {useContext, useState} from "react";

import {Link, navigate} from "gatsby";

import {authContext} from "../contexts/authContext";

import {useFormik} from "formik";

import {Checkbox, PasswordInput, TextInput} from "@mantine/core";

import WithProvider from "./WithProvider";
import ErrorMessage from "./ErrorMessage";

const LoginForm = (props) => {
	const auth = useContext(authContext);

	const [error, setError] = useState("");

	const validate = (values) => {
		setError("");
		const errors = {};
		if (!values.email) {
			errors.email = "Sa ei sisestanud kasutajanime";
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
		) {
			errors.email = "Vale emaili aadress";
		}

		if (!values.password) {
			errors.password = "Sa ei sisestanud parool";
		}

		return errors;
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validate: validate,
		validateOnChange: false,
		onSubmit: (values) => {
			const validationRes = validate(values);
			if (validationRes.email || validationRes.password) {
				// если есть ошибка
				auth.setIsLoading(false);
				return;
			}

			auth
				.signIn(values.email, values.password)
				.then(() => {
					setError("");
					navigate("/");
				})
				.catch((err) => {
					setError(err);
				});
		},
	});

	return (
		<WithProvider>
			{formik.errors.email ? <ErrorMessage error={formik.errors.email}/> : ""}
			{formik.errors.password ? (
				<ErrorMessage error={formik.errors.password}/>
			) : (
				""
			)}
			{error ? <ErrorMessage error={error}/> : ""}

			<form
				className={"w-full max-w-sm mt-10"}
				method="post"
				onSubmit={formik.handleSubmit}
			>
				<TextInput
					className="w-full"
					placeholder="Kasutajanimi või e-posti aadress"
					id="email"
					name="email"
					type="email"
					size="lg"
					required
					onChange={formik.handleChange}
					value={formik.values.email}
					styles={{input: {"&:focus": {border: "1px solid #7AC142"}}}}
				/>
				<PasswordInput
					placeholder="Parool"
					id="password"
					name="password"
					size="lg"
					required
					onChange={formik.handleChange}
					value={formik.values.password}
					styles={{
						root: {"&:focus": {border: "1px solid #7AC142"}},
						rightSection: {margin: "0"},
					}}
				/>

				<div className="flex justify-between w-full mt-3 font-normal">
					<Checkbox
						onChange={(e) => auth.setRememberMe(e.target.checked)}
						styles={{
							input: {cursor: "pointer"},
							label: {cursor: "pointer"},
						}}
						label="Mäleta mind"
						classNames={{
							label: "text-sm sm:text-md m-0",
							input: "checked:bg-green checked:border-green",
						}}
					/>
					<Link className={"m-0"} to={"/forgot"}>
						<p className="text-sm sm:text-md text-green">Unustasin parooli?</p>
					</Link>
				</div>
				<button
					className={"bg-green text-white w-full py-3 rounded-md mt-3 text-sm"}
					type="submit"
				>
					Logi sisse
				</button>
			</form>
		</WithProvider>
	);
};

export default LoginForm